/* eslint-disable */
export declare type SEColor = {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    contrastDefaultColor?: string;
};

/* Primary Colors / User Interface Colors */

export const sapienRed: SEColor = {
    50: '#f9e8e8',
    100: '#efc5c5',
    200: '#e59e9e',
    300: '#da7777',
    400: '#d2595a',
    500: '#ca3c3d',
    600: '#c53637',
    700: '#bd2e2f',
    800: '#b72727',
    900: '#ab1a1a',
    contrastDefaultColor: 'light',
};

export const white: SEColor = {
    50: '#ffffff',
    100: '#fbfbfb',
    200: '#f7f8f8',
    300: '#f3f5f5',
    400: '#f1f2f2',
    500: '#eef0f0',
    600: '#eceeee',
    700: '#e9ecec',
    800: '#e7e9e9',
    900: '#e2e5e5',
    contrastDefaultColor: 'dark',
};

export const gray: SEColor = {
    50: '#eef0f0',
    100: '#d5d8da',
    200: '#b9bfc2',
    300: '#9ca5a9',
    400: '#879196',
    500: '#727e84',
    600: '#6a767c',
    700: '#5f6b71',
    800: '#556167',
    900: '#424e54',
    contrastDefaultColor: 'light',
};

export const black: SEColor = {
    50: '#e8eaea',
    100: '#c6cacc',
    200: '#a1a7aa',
    300: '#7b8387',
    400: '#5e696e',
    500: '#424e54',
    600: '#3c474d',
    700: '#333d43',
    800: '#2b353a',
    900: '#1d2529',
    contrastDefaultColor: 'light',
};

export const darkBlack: SEColor = {
    50: '#202224',
    100: '#182022',
    200: '#182022',
    300: '#13181b',
    400: '#101417',
    500: '#0b0e10',
    600: '#0b0e10',
    700: '#08090a',
    800: '#08090a',
    900: '#000000',
    contrastDefaultColor: 'light',
};

// alias
export const vantaBlack: SEColor = darkBlack;

/* Status Colors */

export const red: SEColor = {
    50: '#f9e8e8',
    100: '#efc5c5',
    200: '#e59e9e',
    300: '#da7777',
    400: '#d2595a',
    500: '#ca3c3d',
    600: '#c53637',
    700: '#bd2e2f',
    800: '#b72727',
    900: '#ab1a1a',
    contrastDefaultColor: 'light',
};

export const orange: SEColor = {
    50: '#feefe4',
    100: '#fcd6bc',
    200: '#fabb90',
    300: '#f7a064',
    400: '#f68b42',
    500: '#f47721',
    600: '#f36f1d',
    700: '#f16418',
    800: '#ef5a14',
    900: '#ec470b',
    contrastDefaultColor: 'light',
};

export const gold: SEColor = {
    50: '#fdf5e4',
    100: '#fbe6bc',
    200: '#f8d58f',
    300: '#f5c462',
    400: '#f2b741',
    500: '#f0aa1f',
    600: '#eea31b',
    700: '#ec9917',
    800: '#e99012',
    900: '#e57f0a',
    contrastDefaultColor: 'dark',
};

export const yellow: SEColor = {
    50: '#fdf9e6',
    100: '#fbefc1',
    200: '#f8e597',
    300: '#f5db6d',
    400: '#f2d34e',
    500: '#f0cb2f',
    600: '#eec62a',
    700: '#ecbe23',
    800: '#e9b81d',
    900: '#e5ac12',
    contrastDefaultColor: 'dark',
};

export const green: SEColor = {
    50: '#e7f6e4',
    100: '#c4e9bc',
    200: '#9cdb90',
    300: '#74cc63',
    400: '#57c141',
    500: '#39b620',
    600: '#33af1c',
    700: '#2ca618',
    800: '#249e13',
    900: '#178e0b',
    contrastDefaultColor: 'light',
};

export const lightBlue: SEColor = {
    50: '#e0f1fd',
    100: '#b3dbfb',
    200: '#80c4f9',
    300: '#4dacf6',
    400: '#269af4',
    500: '#0088f2',
    600: '#0080f0',
    700: '#0075ee',
    800: '#006bec',
    900: '#0058e8',
    contrastDefaultColor: 'dark',
};

export const purple: SEColor = {
    50: '#f3e8fd',
    100: '#e0c5fa',
    200: '#cc9ff7',
    300: '#b779f4',
    400: '#a75cf1',
    500: '#983fef',
    600: '#9039ed',
    700: '#8531eb',
    800: '#7b29e8',
    900: '#6a1be4',
    contrastDefaultColor: 'light',
};

/* Alternate Colors */

export const teal: SEColor = {
    50: '#e0f6f5',
    100: '#b3e8e5',
    200: '#80d9d4',
    300: '#4dc9c3',
    400: '#26beb6',
    500: '#00b2a9',
    600: '#00aba2',
    700: '#00a298',
    800: '#00998f',
    900: '#008a7e',
};

export const citron: SEColor = {
    50: '#f7f9e6',
    100: '#ebefc0',
    200: '#dee496',
    300: '#d0d96c',
    400: '#c6d14d',
    500: '#bcc92d',
    600: '#b6c328',
    700: '#adbc22',
    800: '#a5b51c',
    900: '#97a911',
};

export const pine: SEColor = {
    50: '#e6edea',
    100: '#c0d1cb',
    200: '#97b3a8',
    300: '#6d9485',
    400: '#4d7e6a',
    500: '#2e6750',
    600: '#295f49',
    700: '#235440',
    800: '#1d4a37',
    900: '#123927',
};

export const emerald: SEColor = {
    50: '#e8f1e7',
    100: '#c6dcc2',
    200: '#a0c59a',
    300: '#79ae72',
    400: '#5d9c53',
    500: '#408b35',
    600: '#3a8330',
    700: '#327828',
    800: '#2a6e22',
    900: '#1c5b16',
};

export const wine: SEColor = {
    50: '#f3e2e7',
    100: '#e1b7c2',
    200: '#ce8799',
    300: '#ba5670',
    400: '#ab3252',
    500: '#9c0e33',
    600: '#940c2e',
    700: '#8a0a27',
    800: '#800820',
    900: '#6e0414',
};

export const crimson: SEColor = {
    50: '#fbe6e5',
    100: '#f4c2b3',
    200: '#ed9993',
    300: '#e57068',
    400: '#e05147',
    500: '#da3227',
    600: '#d62d23',
    700: '#d0261d',
    800: '#cb1f17',
    900: '#c2130e',
};

export const sunset: SEColor = {
    50: '#fdf2e6',
    100: '#f9ddbf',
    200: '#f5c795',
    300: '#f1b16b',
    400: '#eea04b',
    500: '#eb8f2b',
    600: '#e98726',
    700: '#e57c20',
    800: '#e2721a',
    900: '#dd6010',
};

export const rust: SEColor = {
    50: '#f8ede5',
    100: '#f0d5bd',
    200: '#e8b892',
    300: '#e09c66',
    400: '#d78645',
    500: '#d17126',
    600: '#cc6a21',
    700: '#c55f1e',
    800: '#c05516',
    900: '#b5430f',
};

export const navy: SEColor = {
    50: '#e0e9ee',
    100: '#b3c8d5',
    200: '#80a3b9',
    300: '#4d7e9c',
    400: '#266287',
    500: '#004672',
    600: '#003f6a',
    700: '#00375f',
    800: '#002f55',
    900: '#002042',
};

export const blue: SEColor = {
    50: '#e0eff8',
    100: '#b3d7ec',
    200: '#80bde0',
    300: '#4da3d4',
    400: '#268fca',
    500: '#007bc1',
    600: '#0073bb',
    700: '#0068b3',
    800: '#005eab',
    900: '#004b9e',
    contrastDefaultColor: 'light',
};

export const sky: SEColor = {
    50: '#f1f8fa',
    100: '#dbeef2',
    200: '#c3e3ea',
    300: '#abd7e1',
    400: '#99cfda',
    500: '#87c6d4',
    600: '#7fc0cf',
    700: '#74b9c9',
    800: '#6ab1c3',
    900: '#57a4b9',
};

export const sage: SEColor = {
    50: '#f7faf3',
    100: '#ecf2e2',
    200: '#e0eacf',
    300: '#d3e1bc',
    400: '#c9daad',
    500: '#c0d49f',
    600: '#bacf97',
    700: '#b2c98d',
    800: '#aac383',
    900: '#9cb972',
};

export const toad: SEColor = {
    50: '#f4f0e3',
    100: '#e3d8ba',
    200: '#d1bf8c',
    300: '#bfa55e',
    400: '#b1913c',
    500: '#a37e19',
    600: '#9b7616',
    700: '#916b12',
    800: '#88610e',
    900: '#774e08',
};

export const butter: SEColor = {
    50: '#fffcf2',
    100: '#fff8df',
    200: '#fff3c9',
    300: '#feeeb3',
    400: '#feeba3',
    500: '#fee793',
    600: '#fee48b',
    700: '#fee080',
    800: '#fedd76',
    900: '#fdd764',
};

export const goldenrod: SEColor = {
    50: '#fef7e7',
    100: '#fcecc2',
    200: '#fadf99',
    300: '#f8d270',
    400: '#f7c952',
    500: '#f5bf33',
    600: '#f4b92e',
    700: '#f2b127',
    800: '#f0a920',
    900: '#ee9b14',
};

export const trophy: SEColor = {
    50: '#fdf6e7',
    100: '#fbe8c2',
    200: '#f9d999',
    300: '#f6ca70',
    400: '#f4be52',
    500: '#f2b333',
    600: '#efac2e',
    700: '#eea327',
    800: '#ec9a20',
    900: '#e88b14',
};
